import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Faq from "react-faq-component";
import scrollToElement from "scroll-to-element";

import { Layout, ContactFormDark } from "../components";

import locales from "../constants";

const iconsData = [
    {
        icon: require("../assets/images/website-analysis-feature-1.svg"),
        title: "Poprawa satysfakcji klientów",
        description:
            "Jeśli użytkownicy łatwiej nawigują po stronie internetowej i są w stanie szybciej odszukać to, czego szukają, są bardziej skłonni do pozostania na stronie i wykonania pożądanych akcji.",
    },
    {
        icon: require("../assets/images/website-analysis-feature-2.svg"),
        title: "Zwiększenie sprzedaży",
        description:
            "Poprawa nawigacji, wyglądu i funkcjonalności strony może znacznie zwiększyć wskaźniki konwersji, takie jak na przykład liczba zakupów, rejestracji czy zapytań.",
    },
    {
        icon: require("../assets/images/website-analysis-feature-3.svg"),
        title: "Budowanie pozytywnego wizerunku marki",
        description:
            "Zapewnienie użytkownikom intuicyjnej i przyjemnej interakcji z witryną przekłada się na pozytywny wizerunek marki.",
    },
    {
        icon: require("../assets/images/website-analysis-feature-4.svg"),
        title: "Zwiększenie konkurencyjności",
        description:
            "W konkurencyjnym środowisku online, dobra witryna internetowa może być kluczowym wyróżnikiem - klienci często wybierają usługi, które oferują im łatwiejsze i bardziej satysfakcjonujące doświadczenia.",
    },
    {
        icon: require("../assets/images/website-analysis-feature-5.svg"),
        title: "Lepsza analiza danych",
        description:
            "Poprawione ścieżki użytkowników i funkcje raportowania mogą dostarczyć cenne dane na temat zachowań klientów.",
    },
    {
        icon: require("../assets/images/website-analysis-feature-6.svg"),
        title: "Zwiększenie retencji klientów",
        description:
            "Pozytywne doświadczenie użytkownika może sprawić, że będzie on bardziej skłonny do powrotu na stronę oraz pozostania na niej dłużej, co wpływa np. na współczynnik odrzuceń (bounce rate), czy czas spędzony na stronie.",
    },
];

const faqData = {
    rows: [
        {
            title: "Jaki jest koszt analizy strony?",
            content:
                "Cena wykonania analizy strony może różnić się w zależności od specyfiki Twojej strony internetowej.",
        },
        {
            title: "Ile czasu zajmuje analiza strony internetowej?",
            content:
                "Czas wykonania audytu Twojej strony internetowej w dużej mierze zależy od jej specyfiki. Zwykle wynosi od 7 do 14 dni roboczych.",
        },
        {
            title: "Kto wdraża poprawki wynikające z przeprowadzonej analizy?",
            content:
                "W analizie znajdują się wszystkie zalecenia do sugerowanych zmian na stronie, które powinieneś wdrożyć we własnym zakresie.",
        },
        {
            title: "Dlaczego warto wykonać analizę strony przed startem kampanii PPC?",
            content: (
                <>
                    <p>
                        Wykonywanie analizy strony przed rozpoczęciem kampanii
                        PPC jest ważne, ponieważ:
                    </p>
                    <ul>
                        <li>Poprawi jakość strony docelowej;</li>
                        <li>Zoptymalizuje doświadczenie użytkownika;</li>
                        <li>Zapewni spójność z reklamami;</li>
                        <li>Poprawi wskaźniki jakości reklam;</li>
                        <li>
                            Zwiększy Twój zwrot z inwestycji w kampanie płatne
                            Google Ads i Facebook Ads;
                        </li>
                        <li>Zidentyfikuje problemy techniczne;</li>
                    </ul>
                    <p>
                        Dzięki naszemu audytowi osiągniesz lepsze wyniki i
                        skuteczność kampanii PPC
                    </p>
                </>
            ),
        },
    ],
};

const WebsiteAnalysisBeforeCampaignStart = ({ location: { pathname } }) => {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const section = document.getElementsByClassName(
                "single-offer-parallax"
            )[0];

            if (section) {
                setScroll(
                    section.offsetTop - section.offsetHeight - window.scrollY
                );
            }
        });
    }, []);

    return (
        <Layout
            seo={{
                title: "Analiza strony internetowej przed startem kampanii PPC",
                description:
                    "Profesjonalna analiza strony internetowej skupiająca się na optymalizacji interakcji klienta i efektywności kampanii PPC. Dowiedz się, jak zoptymalizować Twoją witrynę dla lepszej satysfakcji użytkowników i skuteczności reklam PPC.",
                href: pathname,
                lang: "pl",
            }}
        >
            <span className="anchor" id="home" />
            <div className="subpage-header subpage-header--single-offer">
                <h1 className="subpage-header__title">
                    Analiza strony internetowej
                    <br />
                    przed <span className="inline">startem kampanii PPC</span>
                </h1>
            </div>

            <div className="single-offer-menu-container">
                <div className="row">
                    <div className="col-lg-3">
                        <Link to="/uslugi/">
                            <div className="back-wrapper">
                                <span className="back-wrapper__arrow"></span>
                                <p className="back-wrapper__title">Usługi</p>
                            </div>
                        </Link>
                        <p className="nav-title">Specjalizacje:</p>
                        <ul>
                            <li>
                                <Link to="/konfiguracja-i-wdrożenie-google-analytics-4/">
                                    Konfiguracja i wdrożenie Google Analytics 4
                                </Link>
                            </li>
                            <li>
                                <Link to="/wdrozenie-analityki/">
                                    Wdrożenie analityki
                                </Link>
                            </li>
                            <li>
                                <Link to="/testy-ab-google-optimize/">
                                    Testy A/B
                                </Link>
                            </li>
                            <li className="current">
                                <a href="#home">
                                    Analiza strony internetowej przed startem
                                    kampanii PPC
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="single-offer-sections single-offer-sections--category-4">
                <section className="single-offer-section-text-left single-offer-section-text-left--cat-4">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row">
                                <div className="col-lg-12 text-col">
                                    <div className="row">
                                        <div className="col-lg-11">
                                            <h4 className="text-col__title mb-40">
                                                Dlaczego warto wykonać analizę
                                                strony www przed startem
                                                kampanii PPC?
                                            </h4>
                                            <p className="text-col__desc mb-70">
                                                Zmiany oraz optymalizacje
                                                wprowadzone na bazie naszych
                                                rekomendacji płynących z audytu
                                                strony internetowej mogą
                                                przynieść wiele korzyści, takich
                                                jak:
                                            </p>
                                            <div className="website-analysis-icons mb-70">
                                                {iconsData.map(
                                                    (item, index) => (
                                                        <div key={index}>
                                                            <div className="website-analysis-icons__item">
                                                                <div className="website-analysis-icons__item-icon">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={
                                                                            item.icon
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <h5>
                                                                    {item.title}
                                                                </h5>
                                                                <p>
                                                                    {
                                                                        item.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <h4 className="text-col__title mb-40">
                                                Czy Twoja strona jest gotowa na
                                                kampanie PPC?
                                            </h4>
                                            <p className="text-col__desc mb-50">
                                                Odnotowujesz wiele odwiedzin w
                                                swojej witrynie, ale nie
                                                przekładają się one na
                                                konwersje? Być może problem tkwi
                                                w jakości i użyteczności twojej
                                                strony internetowej. Odwiedzenie
                                                przez użytkownika określonych
                                                adresów domeny, to dopiero
                                                pierwszy krok w budowaniu
                                                sukcesu. Jeśli witryna nie jest
                                                intuicyjna i nie spełnia
                                                oczekiwań odwiedzających,
                                                istnieje ryzyko, że nie znajdą
                                                tego, czego szukają i opuszczą
                                                ją szybko, bez podjęcia
                                                oczekiwanych działań.
                                            </p>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row align-items-center mb-30">
                                                <div className="col-lg-8 text-col">
                                                    <p className="text-col__desc">
                                                        Zapewnienie optymalnego
                                                        doświadczenia dla
                                                        odwiedzających to jeden
                                                        z fundamentów sukcesu w
                                                        środowisku online.
                                                        Skuteczne kampanie PPC
                                                        wymagają stron
                                                        internetowych, które są
                                                        nie tylko atrakcyjne
                                                        wizualnie, ale także
                                                        intuicyjne i przyjazne
                                                        dla użytkownika.
                                                        Użyteczność strony to
                                                        jeden z czynników
                                                        determinujących, czy
                                                        odwiedzający stronę
                                                        staną się Twoimi
                                                        klientami.
                                                    </p>
                                                </div>
                                                <div className="col-lg-4 img-col">
                                                    <img
                                                        className="img-fluid img-col__image"
                                                        src={require("../assets/images/website-analysis-image-1.png")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-11">
                                            <p className="text-col__desc mb-70">
                                                Niezależnie od tego, jak dobrze
                                                zaplanowana jest kampania
                                                reklamowa, jej skuteczność może
                                                zostać znacząco ograniczona,
                                                jeśli strona internetowa, na
                                                którą kieruje ruch, jest
                                                niedopracowana. Dlatego
                                                niezwykle ważne jest, aby
                                                pamiętać o tym, że inwestycja w
                                                duże kampanie reklamowe,
                                                opierające się na niewłaściwie
                                                zaprojektowanych stronach,
                                                często wiąże się z “przepalaniem
                                                budżetu” i marnowaniem
                                                potencjału kampanii.
                                                Optymalizacja strony
                                                internetowej oraz wcześniejsze
                                                poprawki są kluczowymi
                                                czynnościami, które trzeba wziąć
                                                pod uwagę przed rozpoczęciem
                                                kampanii Pay-Per-Click (PPC).
                                            </p>
                                            <p className="text-col__desc mb-70">
                                                <img
                                                    className="img-fluid"
                                                    src={require("../assets/images/website-analysis-image-2.png")}
                                                    alt=""
                                                />
                                            </p>
                                            <h4 className="text-col__title mb-40">
                                                Ocena strony internetowej
                                            </h4>
                                            <p className="text-col__desc mb-50">
                                                Analiza strony internetowej,
                                                która koncentruje się na
                                                zrozumieniu interakcji klienta z
                                                Twoją witryną. Jej celem jest
                                                identyfikacja potencjalnych
                                                obszarów, które wymagają
                                                ulepszeń, tak aby Twoi klienci
                                                mogli cieszyć się łatwym,
                                                intuicyjnym interfejsem i
                                                poruszać się po Twojej witrynie
                                                bez większych przeszkód. To
                                                także weryfikacja, czy analityka
                                                strony jest właściwie
                                                skonfigurowana, oraz czy strona
                                                pozwala na poprawne mierzenie
                                                efektów kampanii, co stanowi
                                                podstawę analizy efektywności
                                                wszystkich prowadzonych działań
                                                marketingowych online. Podczas
                                                analizy strony internetowej
                                                wykorzystujemy nasze wieloletnie
                                                doświadczenie oraz know-how w
                                                prowadzeniu setek kampanii
                                                reklamowych dla różnych branż
                                                tak, aby odpowiednio dostosować
                                                stronę pod kątem konwersji i
                                                poprawić wyniki kampanii PPC.
                                                Wyniki analizy mogą być
                                                wykorzystane do wprowadzenia
                                                usprawnień mających na celu
                                                zwiększenie nie tylko
                                                satysfakcji użytkownika, ale
                                                także właściwe monitorowanie i
                                                analizowanie prowadzonych
                                                kampanii, co może przełożyć się
                                                na realne korzyści biznesowe dla
                                                Twojej firmy w ujęciu zarówno
                                                krótko, jak i długofalowym.
                                            </p>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 text-col">
                                                    <p className="text-col__desc">
                                                        Analiza strony
                                                        internetowej pozwala
                                                        ocenić, czy Twoja
                                                        witryna jest
                                                        przygotowana na
                                                        skuteczne kampanie
                                                        reklamowe typu
                                                        Pay-Per-Click (PPC).
                                                        Pomaga zidentyfikować
                                                        obszary, które mogą
                                                        stanowić przeszkodę dla
                                                        skuteczności kampanii
                                                        PPC. Poprawienie tych
                                                        obszarów może zwiększyć
                                                        współczynnik konwersji i
                                                        obniżyć koszty kliknięć,
                                                        co przekłada się na
                                                        wyższą rentowność
                                                        prowadzonych działań.
                                                    </p>
                                                </div>
                                                <div className="col-lg-4 img-col">
                                                    <img
                                                        className="img-fluid img-col__image"
                                                        src={require("../assets/images/website-analysis-image-3.png")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-pricing-table single-offer-section-text-left--cat-4 website-analysis-table">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 mt-100">
                            <div className="row">
                                <div className="col-lg-11">
                                    <h4 className="text-col__title mb-40">
                                        Obszary, które obejmuje ocena strony
                                        internetowej
                                    </h4>
                                    <p className="text-col__desc">
                                        Analiza strony internetowej przed
                                        startem kampanii PPC polega na diagnozie
                                        kluczowych obszarów związanych z
                                        funkcjonowaniem i wyglądem strony,
                                        takich jak:
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-40">
                                <div className="col-lg-11">
                                    <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span>Obszar</span>
                                                    </th>
                                                    <th>
                                                        <span>status</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="row-label">
                                                        struktura strony www
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        wygląd i estetyka
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        responsywność i
                                                        dostosowanie witryny do
                                                        różnych typów urządzeń
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        użyteczność witryny
                                                        (możliwości uzyskania
                                                        potrzebnych informacji
                                                        lub wykonania
                                                        konkretnych działań, np.
                                                        dodania produktu do
                                                        koszyka, czy złożenia
                                                        zamówienia)
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        wyszukiwarka
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        nawigacja
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        czas ładowania strony
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="row-label">
                                                        nagłówek i stopka
                                                        serwisu
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={require("../assets/images/ico-checkmark.svg")}
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-11">
                                    <p className="text-col__desc mb-40">
                                        Zajmujemy się także weryfikacją
                                        konfiguracji narzędzi analitycznych
                                        witryny, aby upewnić się, że możliwy
                                        jest precyzyjny pomiar efektów
                                        prowadzonych działań reklamowych,
                                        bazując na naszym wieloletnim
                                        doświadczeniu oraz bogatej wiedzy,
                                        nabytej w trakcie przeprowadzania
                                        licznych kampanii PPC na przestrzeni
                                        ostatnich lat.
                                    </p>
                                    <Link
                                        className="btn btn-box btn--website-analysis"
                                        to="/brief/"
                                    >
                                        Darmowa konsultacja
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-text-left single-offer-section-text-left--cat-4">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 mt-100">
                            <div className="row">
                                <div className="col-lg-12 text-col">
                                    <div className="row">
                                        <div className="col-lg-11">
                                            <h4 className="text-col__title mb-40">
                                                Co otrzymujesz w ramach analizy
                                                strony internetowej?
                                            </h4>
                                            <p className="text-col__desc">
                                                Wykonując analizę Twojej witryny
                                                internetowej pod kątem
                                                realizacji kampanii PPC
                                                sprawdzimy, czy jej potencjał
                                                jest w pełni wykorzystywany, czy
                                                analityka strony jest właściwie
                                                skonfigurowana, oraz czy strona
                                                pozwala na poprawne mierzenie
                                                efektów kampanii. Dodatkowo,
                                                bazując na naszym wieloletnim
                                                doświadczeniu w prowadzeniu
                                                kampanii PPC, przedstawimy Ci
                                                ocenę ogólną strony internetowej
                                                oraz dokładne zalecenia zmian,
                                                które powinieneś wdrożyć. Możesz
                                                także skorzystać z konsultacji z
                                                dedykowanym specjalistą oraz z
                                                analizy efektów wprowadzonych
                                                przez Ciebie zmian po ustalonym
                                                terminie.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    className="single-offer-parallax"
                    style={{ backgroundPositionX: scroll }}
                >
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row">
                                <div className="col-lg-11">
                                    <h2>
                                        Razem wyznaczmy ścieżkę
                                        <br />
                                        do <span>Twojego sukcesu</span> online!
                                    </h2>
                                    <p>
                                        Jeśli zależy Ci na poprawie wyników
                                        kampanii PPC i jesteś gotowy na analizę
                                        swojej witryny internetowej, skontaktuj
                                        się z nami i otrzymaj kompleksowy raport
                                        z oceną witryny oraz rekomendacjami
                                        zmian.
                                    </p>
                                    <button
                                        className="btn btn-line"
                                        onClick={() =>
                                            scrollToElement("#kontakt", {
                                                duration: 500,
                                                offset: 0,
                                            })
                                        }
                                    >
                                        Napisz do nas
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-text-left--cat-4 single-offer-section-text-left faq-webiste-analysis">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <h2 className="text-col__title mb-40">
                                FAQ Analiza strony internetowej
                            </h2>
                            <Faq
                                data={faqData}
                                styles={{
                                    rowContentPaddingBottom: "60px",
                                    rowContentPaddingTop: "10px",
                                }}
                                config={{
                                    arrowIcon: (
                                        <img
                                            src={require("../assets/images/ico-faq_arrow.svg")}
                                            alt=""
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-contact">
                    <div className="form-wrapper">
                        <h2 className="single-offer-section-contact__title mb-40">
                            Skontaktuj się z nami! <br />
                            Wypełnij <span>formularz</span>
                        </h2>
                        <ContactFormDark locales={locales["pl"]} />
                    </div>
                    <img
                        className="img-fluid single-offer-section-contact__hand"
                        src={require("../assets/images/home-contact-hand-right.png")}
                        alt=""
                    />
                    <div className="single-offer-anchor" id="kontakt" />
                </section>
            </div>
        </Layout>
    );
};

export default WebsiteAnalysisBeforeCampaignStart;
